import { signTypedData } from '@wagmi/core'
import { getAccount } from '@wagmi/core'
import { Wagmi } from '@/web3/config/wagmi.js';

export async function signDelegate(delegateAddress, chainId) {
  const { connector } = getAccount(Wagmi.config)

  const domain = {
    name: 'Safe Transaction Service',
    version: '1.0',
    chainId: chainId
  }

  const types = {
    Delegate: [
      { name: 'delegateAddress', type: 'address' },
      { name: 'totp', type: 'uint256' }
    ]
  }

  const totp = Math.floor(Date.now() / 1000 / 3600)

  const result = await signTypedData(Wagmi.config, {
    connector,
    domain,
    types,
    primaryType: 'Delegate',
    message: { delegateAddress, totp }
  })

  return result
}
