import { Controller } from '@hotwired/stimulus';
import confirmTransaction from '@/web3/services/confirmTransactionService';
import executeTransaction from '@/web3/services/executeTransactionService';
import { SafeContract } from '@/web3/utils/safeContract';
import { get, put } from "@rails/request.js";
import { switchNetwork } from '@wagmi/core';
import { config, Wagmi } from '@/web3/config/wagmi.js';
import { WalletManager } from '@/web3/logic/wallet_manager.js';
import {  waitForTransactionReceipt } from '@wagmi/core';

export default class extends Controller {
  static targets = ["confirmButton", "executeButton", "pendingMessage", "mainContent"];
  static values = {
    address: String,
    chain: Number,
    hash: String,
    status: String,
    confirmations: Array,
    id: Number
  };

  connect() {
    document.addEventListener('accountChanged', e => this.updateUI(e.detail));
    if (WalletManager.isConnected) {
      this.updateUI(WalletManager.connector);
    }
  }

  disconnect() {
    console.log("Transactions Controller disconnected");
  }

  async confirm() {
    try {
      this.toggleButtonState(this.confirmButtonTarget, false);
      const result = await confirmTransaction(this.chainValue, this.hashValue);
      this.toggleProcessing(true);
    } catch (error) {
      console.error('Confirmation failed:', error);
      this.toggleButtonState(this.confirmButtonTarget, true);
    }
  }

  async execute() {
    try {
      this.toggleButtonState(this.executeButtonTarget, false);
      const hash = await executeTransaction(this.addressValue, this.chainValue, this.hashValue);
      this.toggleProcessing(true);
      const recepit =  await waitForTransactionReceipt(Wagmi.config, { hash: hash, confirmation: 12 });
      const body = { transaction: { block_number: Number(recepit["blockNumber"]) } };
      const response = await put(`/transactions/${this.idValue}/sync`, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      });

     
    } catch (error) {
      console.error('Execution failed:', error);
      this.toggleButtonState(this.executeButtonTarget, true);
    }
  }

  async isOwner(address) {
    const safeContract = new SafeContract(this.addressValue);
    return safeContract.isOwner(address);
  }

  async updateUI(account) {
    if (WalletManager.isConnected) {
      if (WalletManager.chain.id !== this.chainValue) {
        await switchNetwork(config(), { chainId: this.chainValue });
      }

      const isOwner = await this.isOwner(account.address);
      const hasConfirmed = this.confirmationsValue.some(confirmation => confirmation.toLowerCase() === account.address.toLowerCase());
      const isCompleted = ['failed', 'successful'].includes(this.statusValue);
      const isExecutable = this.statusValue === 'executable';

      this.toggleButtonVisibility(this.confirmButtonTarget, isOwner && !isCompleted && !hasConfirmed);
      this.toggleButtonState(this.confirmButtonTarget, isOwner && !isCompleted && !hasConfirmed);

      this.toggleButtonVisibility(this.executeButtonTarget, isOwner && !isCompleted && isExecutable);
      this.toggleButtonState(this.executeButtonTarget, isOwner && !isCompleted && isExecutable);

    } else {
      this.toggleButtonVisibility(this.confirmButtonTarget, false);
      this.toggleButtonState(this.confirmButtonTarget, false);

      this.toggleButtonVisibility(this.executeButtonTarget, false);
      this.toggleButtonState(this.executeButtonTarget, false);
    }
  }

  toggleProcessing(isProcessing) {
    this.mainContentTarget.classList.toggle('hidden', isProcessing);
    this.pendingMessageTarget.classList.toggle('hidden', !isProcessing);
  }

  toggleButtonVisibility(button, isVisible) {
    button.classList.toggle('hidden', !isVisible);
  }

  toggleButtonState(button, isEnabled) {
    button.classList.toggle('opacity-50', !isEnabled);
    button.classList.toggle('cursor-not-allowed', !isEnabled);
    button.disabled = !isEnabled;
  }
}
