import { getAddress } from 'viem'

// Uniswap Addresses
export const QUOTER_CONTRACT_ADDRESS = getAddress('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6')
export const UNISWAP_ROUTER_CONTRACT_ADDRESS = getAddress(
  '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'
)

// TODO: replace by production data from database, etc.
export const TIME_INTERVAL = 108

export const MAX_SAFE_ALLOWANCE = "79228162514264337593543950335";

