import { formatUnits, parseUnits } from 'viem'

export const bigIntify = (val) => BigInt(val)
export const stringify = (val) => val.toString()

const READABLE_FORM_LEN = 4

export const fromReadableAmount = (amount, decimals) => {
  return parseUnits(amount.toString(), decimals)
}

export const toReadableAmount = (rawAmount, decimals, display_lenght = READABLE_FORM_LEN) => {
  return formatUnits(rawAmount, decimals).slice(0, display_lenght)
}
