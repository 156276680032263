import { Controller } from '@hotwired/stimulus';
import { get, post } from '@rails/request.js';
import { signDelegate } from '@/web3/logic/signDelegate.js';
import { WalletManager } from '@/web3/logic/wallet_manager.js';

export default class extends Controller {
  static targets = ["safesList", "message"]

  connect() {
    document.addEventListener('accountChanged', (e) => this.updateUI(e.detail));
    this.updateUI(WalletManager.connector);
  }

  updateUI(account) {
    if (account.isConnected) {
      this.fetchSafes();
    }
  }

  async fetchSafes() {
   // this.messageTarget.innerHTML = "Wait, loading in progress...";
    const address = WalletManager.connector.address;
    const chain_id = WalletManager.connector.chain.id;
    const response = await get(`/safes/fetchings?address=${address}&chain_id=${chain_id}`, {
      responseKind: 'turbo-stream',
    });
    this.safesListTarget.innerHTML = await response.text;
  }

  async addSafe(event) {
    const safeAddress = event.target.dataset.address;
    const delegate = event.target.dataset.delegate;
    const chainID = WalletManager.connector.chain.id;
    const delegator = WalletManager.connector.address;
    const signature = await signDelegate(delegate, chainID);

    console.log(signature)

    await post('/safes', {
      body: JSON.stringify({
        chain: chainID,
        signature: signature,
        safe_address: safeAddress,
        delegator: delegator
      }),
      headers: { 'Content-Type': 'application/json' },
      responseKind: 'turbo-stream'
    });

  }

  refresh() {
    this.fetchSafes();
  }
}