import { readContract, getBalance, writeContract } from '@wagmi/core';
import { Wagmi } from '@/web3/config/wagmi.js';
import { ISAFE_ABI } from '@/web3/abis/ISAFE_ABI';

export class SafeContract {
  constructor(address) {
    this.address = address;
    this.readContract = this.readContract.bind(this);
    this.getOwners = this.getOwners.bind(this);
    this.getThreshold = this.getThreshold.bind(this);
    this.approvedHashes = this.approvedHashes.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.getOwnersWhoApprovedTx = this.getOwnersWhoApprovedTx.bind(this);
    this.executeTransaction = this.executeTransaction.bind(this);
  }

  async readContract(functionName, args = []) {
    return await readContract(Wagmi.config, {
      address: this.address,
      abi: ISAFE_ABI,
      functionName,
      args,
    });
  }

  async writeTransaction(functionName, args = []) {
    return await writeContract(Wagmi.config, {
      address: this.address,
      abi: ISAFE_ABI,
      functionName,
      args: args,
    });
  }

  async getOwners() {
    return this.readContract('getOwners');
  }

  async isOwner(ownerAddress) {
    return await this.readContract('isOwner', [ownerAddress]);
  }

  async getThreshold() {
    return this.readContract('getThreshold');
  }

  async approvedHashes(owner, txHash) {
    return this.readContract('approvedHashes', [owner, txHash]);
  }

  async getBalance(address) {
    return getBalance(Wagmi.config, { address });
  }

  async getOwnersWhoApprovedTx(txHash) {
    const owners = await this.getOwners();
    const ownersWhoApproved = [];

    for (const owner of owners) {
      const approved = await this.approvedHashes(owner, txHash);
      if (approved > 0) {
        ownersWhoApproved.push(owner);
      }
    }
    return ownersWhoApproved;
  }

  async executeTransaction(args) {
    return await this.writeTransaction('execTransaction', args);
  }
}
