export class RenderErrors {
  static addNewError(message, id) {
    const errorAlert = document.getElementById('errorAlert')
    errorAlert.classList.remove('hidden')

    const errorContainer = document.getElementById('error')
    if (document.getElementById(`error-${id}`)) return
    const newErrorElement = document.createElement('div')
    newErrorElement.id = `error-${id}`
    newErrorElement.innerText = message
    errorContainer.appendChild(newErrorElement)
  }

  static removeError(id) {
    const elementToRemove = document.getElementById(`error-${id}`)
    if (elementToRemove) {
      elementToRemove.parentNode.removeChild(elementToRemove)
    }
  }

  static removeAllErrors() {
    const errorAlert = document.getElementById('errorAlert')
    errorAlert.classList.add('hidden')

    const errorContainer = document.getElementById('error')
    errorContainer.innerHTML = ''
  }
}
