import { 
    connect, 
    disconnect, 
    getAccount, 
    reconnect, 
    watchAccount, 
    switchChain, 
    signMessage ,
} from '@wagmi/core';

import { Wagmi } from '@/web3/config/wagmi.js';
import { RenderErrors } from '@/web3/ui/renderErrors.js';
import { polygon } from '@wagmi/core/chains';

export class WalletManager {

    static get isConnected() {
        return this.connector.isConnected;
    }

    static get connectors() {
        return Wagmi.config.connectors;
    }

    static get chains() {
        return Wagmi.config.chains;
    }

    static get chain() {
        return this.connector.chain
    }


    static get connector() {
        return getAccount(Wagmi.config);
    }

    static get address() {
        return this.connector.address;
    }

    static get addressTrim() {
        const address = this.address;
        return `${address.slice(0, 5)}...${address.slice(-4)}`;
    }

    static async connect(connector) {
        try {
            await connect(Wagmi.config, { connector });
        } catch (error) {
            console.error("Error connecting wallet:", error);
            RenderErrors.display(error);
        }
    }

    static async disconnect() {
        await disconnect(Wagmi.config);
        localStorage.clear();
    }

    static getConnector(connectorId) {
        return this.connectors.find(conn => conn.id === connectorId);
    }

  static async signMessage(message) {

    return  await signMessage(Wagmi.config, { message: { raw: message } } )
    }

    static watchAccountChanges(id = 137) {
        watchAccount(Wagmi.config, {
            onChange: async (account) => {
                if (account.isConnected && !Wagmi.config.chains.some(chain => chain.id === account.chainId)) {
                    await switchChain(Wagmi.config, { chainId: id });
                }
                document.dispatchEvent(new CustomEvent('accountChanged', { detail: account }));
            }
        });
    }

    static async reconnect() {
        try {
            await reconnect(Wagmi.config);
        } catch (error) {
            console.error("Error reconnecting wallet:", error);
            RenderErrors.display(error);
        }
    }
}
