import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ["success", "error"]

  submit(event) {
    event.preventDefault();
    const form = this.element;
    const formData = new FormData(form);

    fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      }
    }).then(response => response.json())
      .then(data => {
         this.showSuccess();

      })
      .catch((error) => {
        this.hideSuccess();
        console.error('Error:', error);
      });
  }

  showSuccess() {
    this.successTarget.classList.remove('hidden')
    setTimeout(() => {
      this.successTarget.classList.remove('opacity-0')
    },300)

  }

  hideSuccess() {
    this.element.reset()
    this.successTarget.classList.add('opacity-0')
    setTimeout(() => {
      this.successTarget.classList.add('hidden')
    },300)


  }

}
