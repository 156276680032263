import { generatePreValidatedSignature, generateSignature, buildSignatureBytes } from '@safe-global/protocol-kit/dist/src/utils/signatures/utils.js';
import { getAccount } from '@wagmi/core';
import SafeApiKit from '@safe-global/api-kit';
import Safe from '@safe-global/protocol-kit';

import { SafeContract } from '@/web3/utils/safeContract';
import { WalletManager} from '@/web3/logic/wallet_manager.js';

function buildSignature(transaction) {
  const signatures = transaction.confirmations.map(confirmation => ({
    data: confirmation.signature,
    signer: confirmation.owner
  }));
  return buildSignatureBytes(signatures);
}


async function executeSafeTransaction(address, chainId, txSafeHash) {
    const apiKit = new SafeApiKit({
      chainId: BigInt(chainId)
    });

    const safeContract = new SafeContract(address);
    const safeTransaction = await apiKit.getTransaction(txSafeHash);
    const signature = buildSignature(safeTransaction);

    const value = BigInt(safeTransaction.value);
    if (value !== 0n) {
      const balance = await safeContract.getBalance(address);
      if (value > balance) {
        throw new Error('Not enough Ether funds');
      }
    }

    const transactionResponse = await safeContract.executeTransaction(
      [     safeTransaction.to,
            safeTransaction.value,
            safeTransaction.data,
            safeTransaction.operation,
            safeTransaction.safeTxGas.toString(),
            safeTransaction.baseGas.toString(),
            safeTransaction.gasPrice,
            safeTransaction.gasToken,
            safeTransaction.refundReceiver,
            signature]
    );

    return transactionResponse;

}

export default executeSafeTransaction;
