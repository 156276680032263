import { readContract, simulateContract } from '@wagmi/core'
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import Quoter from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
import { computePoolAddress, FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { QUOTER_CONTRACT_ADDRESS } from '@/web3/config/constants.js'
import { Wagmi } from '@/web3/config/wagmi.js'

export const currentPoolAddress = (tokenIn, tokenOut, feeAmount) => {
  return computePoolAddress({
    factoryAddress: FACTORY_ADDRESS,
    tokenA: tokenIn,
    tokenB: tokenOut,
    fee: feeAmount,
  })
}

export const quoteExactInput = async (amountIn, tokenIn, tokenOut, feeAmount) => {
  const poolFees = await getPoolFee(tokenIn, tokenOut, feeAmount)

  const quotedAmountOut = await simulateContract(Wagmi.config, {
    abi: Quoter.abi,
    address: QUOTER_CONTRACT_ADDRESS,
    functionName: 'quoteExactInputSingle',
    args: [tokenIn.address, tokenOut.address, poolFees, amountIn, 0],
  })

  return quotedAmountOut
}

export const quoteExactOutput = async (amountOut, tokenIn, tokenOut, feeAmount) => {
  const poolFees = await getPoolFee(tokenIn, tokenOut, feeAmount)

  const quotedAmountIn = await simulateContract(Wagmi.config, {
    abi: Quoter.abi,
    address: QUOTER_CONTRACT_ADDRESS,
    functionName: 'quoteExactOutputSingle',
    args: [tokenIn.address, tokenOut.address, poolFees, amountOut, 0],
  })

  return quotedAmountIn
}

export const getPoolFee = async (tokenIn, tokenOut, feeAmount) => {
  const poolAddress = currentPoolAddress(tokenIn, tokenOut, feeAmount)

  const fee = await readContract(Wagmi.config, {
    address: poolAddress,
    abi: IUniswapV3PoolABI.abi,
    functionName: 'fee',
  })

  return fee
}
