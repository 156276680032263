import { walletConnect, coinbaseWallet } from '@wagmi/connectors'
import { createConfig, http } from '@wagmi/core'
import { mainnet, polygon, sepolia  } from '@wagmi/core/chains'

export const polygonFork = {
  ...polygon,
  name: 'Polygon Virtual',
  id: 12345,
  rpcUrls: {
    default: { http: ['https://virtual.polygon.rpc.tenderly.co/5a8b7959-0296-4f26-a662-ea4f5369e7ee'] },
  },
}

export const projectId = '8f6358bd08035e9d239e4cdfc117b8b9'
export const connectors = [
coinbaseWallet({ appName: 'scionx', preference: 'smartWalletOnly' }), 
  walletConnect({
      projectId: projectId,
    })]

export class Wagmi {
  static config = createConfig({
    chains: [polygonFork,polygon,sepolia],
    connectors: connectors,
    transports: {
        [polygon.id]: http("https://polygon.gateway.tenderly.co/5IDdy2wbxbbvuQmhXO8Cgj"),
        [polygonFork.id]: http("https://virtual.polygon.rpc.tenderly.co/5a8b7959-0296-4f26-a662-ea4f5369e7ee"),
        [sepolia.id]: http("https://sepolia.gateway.tenderly.co/693RU3w6Dh2rzBUzQjFGQ5")
    },
  })

}

export function config() {
  return Wagmi.config
}

