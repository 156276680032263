import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["loading"];

  async loadSafes(event) {
    const orgId = event.target.value;
    if (orgId) {
      const url = `/organizations/${orgId}/safes`;
      const params = new URLSearchParams();

      this.loadingTarget.classList.remove('hidden');

      const request = get(`${url}?${params}`, { responseKind: 'turbo-stream' });
      request.then((response) => {
        if (response.ok) {
          this.loadingTarget.classList.add('hidden');
        }
      });
    }
  }
}
