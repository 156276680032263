import getChainLogoPath from '@/controllers/helpers/getChainLogoPath'

export class RenderCheckout {
  static paymentInfo(name, info) {
    return `
    <div class="flex justify-between gap-5 text-sm whitespace-nowrap mt-2.5">
      <div class=" text-[#6B6E82]">
        ${name}
      </div>
      <div class="text-white truncate">
       ${info}
      </div>
    </div>
    `
  }

  static chain(chain) {
    return `
      <span class="inline-flex items-center gap-2">
        <img src="${getChainLogoPath(chain.name)}" alt="${chain.name}" class="h-5 w-5" >
        ${chain.name}
      </span>
    `
  }
  static status(status) {
    let cssClasses = ''

    switch (status) {
      case 'pending':
        cssClasses = 'text-[#F0B90B] bg-[#F79009]'
        break
      case 'failed':
        cssClasses = 'text-[#D74E4E] bg-[#D74E4E]'
        break
      case 'paid':
        cssClasses = 'text-[#9FE788] bg-[#9FE788]'
        break
      default:
        return
    }

    return `
      <span class="inline-flex items-center gap-1 py-1 px-2.5  text-xs bg-opacity-15 rounded-2xl ${cssClasses}">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="3" fill="currentColor"/>
        </svg>

        ${status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    `
  }
}
