import { Wagmi, config } from '@/web3/config/wagmi.js'

 const getChainById = (chainId) => {
  const currentChain = Wagmi.config.chains.find((chain) => chain.id === chainId);
  if(!currentChain) console.log('chain not found');
  return currentChain;
}


export default getChainById;
