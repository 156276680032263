import { Controller } from '@hotwired/stimulus'
import { Wagmi } from '@/web3/config/wagmi'
import { Charge } from '@/web3/logic/charge.js'
import { get } from '@rails/request.js'
import { Token } from '@uniswap/sdk-core'
import { getAddress } from 'viem'

export default class extends Controller {
  static outlets = ['visibility']
  static targets = ['tokens', 'token']
  static values = {
    showTokens: false,
  }

  async connect() {
    document.addEventListener('drawMethods', this.drawMethods.bind(this))
    
  }
  disconnect() {
    document.removeEventListener('drawMethods', this.drawMethods.bind(this))
  }

  handleClick(e) {
    this.visibilityOutlet.showLoader()
    if (this.showTokensValue) {
      this.selectToken(e.currentTarget)
    } else {
      this.showTokens()
    }
    this.visibilityOutlet.hideLoader()
  }

  showTokens() {
    this.showTokensValue = true
    this.updateTokenVisibility()
    this.visibilityOutlet.showTokens()
  }

  updateTokenVisibility() {
    this.tokenTargets.forEach((token) => {
      token.classList.toggle('hidden', !this.showTokensValue)
    })
  }

  selectToken(selectedToken) {
    const tokenCurrentValue = JSON.parse(selectedToken.dataset["web3-TokenTokenValue"])
    this.showTokensValue = false
    this.tokenTargets.forEach((token) => {
      const shouldBeHidden = token !== selectedToken
      token.classList.toggle('hidden', shouldBeHidden)
    })

    this.dispatchEvent('tokenSelected', new Token(tokenCurrentValue.chain_id,
                          getAddress(tokenCurrentValue.address),
                          tokenCurrentValue.decimals,
                          tokenCurrentValue.symbol,
                          tokenCurrentValue.name) )
    this.visibilityOutlet.hideTokens()
  }

  async drawMethods() {
    this.visibilityOutlet.showLoader()
    const charge = await Charge.current
    const payerAddress = charge.address
    const chainId = Wagmi.config.state.chainId
    await get(`/wallet?address=${payerAddress}&chain_id=${chainId}`, {
        contentType: 'application/json',
        responseKind: 'turbo-stream',
      })

    this.dispatchEvent('showNetworkFee')
    this.visibilityOutlet.hideLoader()
  }
  

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }))
  }
}
