import { readContract } from '@wagmi/core'
import { IERC20_ABI } from '@/web3/abis/IERC20_ABI'
import { Wagmi } from '@/web3/config/wagmi.js'

export const getTokenAllowance = async (tokenAddress, spenderAddress, accountAddress) => {
  const allowance = await readContract(Wagmi.config, {
    address: tokenAddress,
    abi: IERC20_ABI,
    functionName: 'allowance',
    args: [accountAddress, spenderAddress],
  })
  return allowance
}
