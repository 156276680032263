import { adjustVInSignature } from '@safe-global/protocol-kit/dist/src/utils/signatures/utils.js';
import { EthSafeSignature } from '@safe-global/protocol-kit/dist/src/utils/signatures/SafeSignature.js';
import SafeApiKit from '@safe-global/api-kit';
import { WalletManager } from '@/web3/logic/wallet_manager.js';
import { toBytes } from 'viem'

export async function generateSignature(txSafeHash) {
    const messageArray = toBytes(txSafeHash)
    const sign = await WalletManager.signMessage(messageArray)
    const adjSignature = adjustVInSignature('eth_sign', sign, txSafeHash, WalletManager.address);
    return new EthSafeSignature(WalletManager.address, adjSignature);
}

export async function confirmSafeTransaction(chainId, txSafeHash) {
   const signature = await generateSignature(txSafeHash);
   const apiKit = new SafeApiKit({ chainId: BigInt(chainId) });
   return await apiKit.confirmTransaction(txSafeHash, signature.data);
}

export default confirmSafeTransaction;
