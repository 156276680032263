import { Wagmi } from '../config/wagmi.js'
import { WalletManager } from '../logic/wallet_manager.js'

export class UIConnectButtons {
  constructor(container) {
    this.container = container
  }

  renderButtons() {
    const buttonsHtml = Wagmi.config.connectors
      .map((connector) => this.buttonHtml(connector))
      .join('')
    this.container.innerHTML = buttonsHtml
    this.attachEventListeners()
  }

  buttonHtml(connector) {
    const icon = connector.icon
      ? connector.icon
      : new URL(`../../images/${connector.type.toLowerCase()}.svg`, import.meta.url).href
    return `
      <div class="mt-1">
        <button id="${connector.uid}" class="connect h-16 w-full rounded-lg bg-white bg-opacity-[0.02] border border-[#0F0E0F]">
          <div class="flex items-center gap-3 px-4 py-2 w-full">
            <div class="w-7 h-7">
              <img src="${icon}" alt="${connector.name}" class="w-full h-full object-contain">
            </div>

            <div class="flex-1 shrink">
              <p class="text-white text-start font-medium">${connector.name}</p>
            </div>
            <span class="">
              <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.98199 10.5994C1.6891 10.8923 1.21422 10.8923 0.92133 10.5994C0.628437 10.3065 0.628437 9.83159 0.92133 9.5387L4.7656 5.69443L0.92133 1.85016C0.628437 1.55727 0.628437 1.0824 0.92133 0.789503C1.21422 0.49661 1.6891 0.49661 1.98199 0.789503L6.35432 5.16183C6.35508 5.16258 6.35584 5.16334 6.35659 5.1641C6.42982 5.23732 6.48474 5.32192 6.52135 5.4122C6.59457 5.59277 6.59457 5.79608 6.52135 5.97665C6.48474 6.06694 6.42982 6.15154 6.35659 6.22477C6.35584 6.22552 6.35508 6.22628 6.35432 6.22703L1.98199 10.5994Z" fill="#827E91"/>
              </svg>
            </span>
          </div>
        </button>
      </div>
    `
  }

  attachEventListeners() {
    this.container.querySelectorAll('.connect').forEach((button) => {
      const connector = Wagmi.config.connectors.find((c) => c.uid === button.id)
      button.addEventListener('click', () => WalletManager.connect(connector))
    })
  }
}
