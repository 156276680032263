import { decodeEventLog, formatUnits } from 'viem';
import { IPAYMENTS_ABI } from '@/web3/abis/IPAYMENTS_ABI.js';

// Function to decode and filter logs for Transferred events
export async function TransferredEventDecoder(logs, chain) {
  const transferredEventSignature = '0xb7ee4fafa10d16ee883a136a3dcc9179d8e6041ba71cc29c2b0bce4f7bdd6e86';

  async function getTokenSymbol(address, chain) {
    if (address === "0x0000000000000000000000000000000000000000") {
      return chain.currency;
    }
    try {
      const response = await fetch(`/tokens?address=${address}&chain_id=${chain.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        console.error('Failed to fetch token symbol:', response);
        return null;
      }
      const data = await response.json();
      return data.symbol;
    } catch (error) {
      console.error('Error fetching token symbol:', error);
      return null;
    }
  }

  const decodedLogs = await Promise.all(logs
    .filter(log => log.topics[0] === transferredEventSignature)
    .map(async (log) => {
      try {
        const decodedLog = decodeEventLog({
          abi: IPAYMENTS_ABI,
          data: log.data,
          topics: log.topics,
          strict: true
        });

        const spentSymbol = await getTokenSymbol(decodedLog.args.spentCurrency, chain);
        return {
          spentAmount: formatUnits(decodedLog.args.spentAmount, 18),
          spentCurrency: decodedLog.args.spentCurrency,
          spentSymbol: spentSymbol
        };
      } catch (logError) {
        console.error('Error decoding log:', logError);
        console.error(`Log data: ${log.data}`);
        return null;
      }
    })
  );

  return decodedLogs.filter(Boolean); // Filter out null values from failed decoding attempts
}
