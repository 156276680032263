import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu', 'button']
  static values = {
    openButtonClasses: String,
  }
  connect() {
    this.close()
  }

  toggle(e) {
    if (this.menuTarget.classList.contains('hidden')) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    this.menuTarget.classList.remove('hidden')
    this.buttonTarget.classList.add(...this.openButtonClassesValue.split(' '))
  }

  close() {
    this.menuTarget.classList.add('hidden')
    this.buttonTarget.classList.remove(...this.openButtonClassesValue.split(' '))
  }
}
